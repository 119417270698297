import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import {session} from '../js/storage'

const routes = [
  {path: '/', redirect: '/devices'},
  {path: '/devices', name: 'Devices', component: Home},
  {path: '/about', name: 'About', component: () => import('../views/About.vue')},
  {path: '/login', name: 'Login', component: () => import('../views/Login.vue')},
  {path: '/upload', name: 'Upload', component: () => import('../views/Upload.vue')},
  {path: '/logout', name: 'Logout', component: () => import('../views/Logout.vue')}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(
  (to, from, next) => {
    if(to.name!=="Login") {
      if(typeof session.get === 'function')
      {
          if(session.get("authenticated")!=true) {
            next({ name: 'Login' })
          } else next();
      }
      else {
        next({ name: 'Login' })
      }
  }
  else next();
}
)

export default router
