import { createApp } from 'vue'


import App from './App.vue'
import axios from 'axios';
import router from './router'
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import vueTheStorages from 'vue-the-storages'
import Emitter from 'tiny-emitter';

const app = createApp(App)
let emitter = new Emitter();

const URL = [
    "http://localhost:8080",
    "https://coap.herc.ro"
]

axios.defaults.withCredentials = true
axios.defaults.baseURL = URL[1];        //environment variable

app.use(router)
app.use(VuesticPlugin);
app.use(vueTheStorages);
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = emitter;
app.mount('#app')