import axios from 'axios';
import {session} from "./storage"

export function GetFirmwareNames(callback){
    let url = "https://wmsgetfirmwarenames.azurewebsites.net/api/GetFirmwareNames?code=ErjueW48LoiNcwATiE2Lma5pYmWvJ/JeFhMLoSaxYg3QOwO06h0U2A=="
    let auth_url =  "https://wmsgetfirmwarenames.azurewebsites.net/.auth/login/aad"
    let auth_body = {"access_token" : session.get("idToken")}
    axios.post(auth_url, auth_body).then(response => { 
      let authenticationToken = response.data.authenticationToken;
      let header = {"X-ZUMO-AUTH" : authenticationToken}
      axios.get(url, {headers: header}).then(response => { 
        callback(response.data)
      });
    });
}

export function GetVersions(firmware_name, callback){
    let url = "https://wmsgetversions.azurewebsites.net/api/GetVersions?code=qZSDa2OqStM88bEb2YlZV39YiOhltglvjNtabsKnyI/0XWaNj1J1AA=="
    let auth_url =  "https://wmsgetversions.azurewebsites.net/.auth/login/aad"
    let auth_body = {"access_token" : session.get("idToken")}
    axios.post(auth_url, auth_body).then(response => { 
      let authenticationToken = response.data.authenticationToken;
      let header = {"X-ZUMO-AUTH" : authenticationToken}
      axios.post(url, {name:firmware_name}, {headers: header}).then(response => { 
         // console.log("Returning: ", JSON.stringify(response.data))
          callback(response.data)
      });
    });
}

export function DeleteVersion(firmware_name, version, callback){
    let url = "https://wmsdeletefirmwareapi.azurewebsites.net/api/WMSDeleteFirmwareAPI?code=giC67u/9sZqKn7nE/aT86yS3fTEQaCpTwtg6KH9OYgPE0QW14ADpzw=="
    let auth_url =  "https://wmsdeletefirmwareapi.azurewebsites.net/.auth/login/aad"
    let auth_body = {"access_token" : session.get("idToken")}
    axios.post(auth_url, auth_body).then(response => { 
      let authenticationToken = response.data.authenticationToken;
      let header = {"X-ZUMO-AUTH" : authenticationToken}
      axios.post(url, {name:firmware_name, version:version},{headers: header}).then(response => { 
         // console.log("Returning: ", JSON.stringify(response.data))
          callback(response.data)
      });
    });
}

export function UploadVersion(firmware_name, version, data, callback){
    let url = "https://wmsfirmwareupload.azurewebsites.net/api/WMSFirmwareUpload?code=nnBswQEXnxZE87iTkDO0/JTdCZbqrqnfikKGZ8sLbMQEMMXgYCLYLw==";
    let auth_url =  "https://wmsfirmwareupload.azurewebsites.net/.auth/login/aad"
    let auth_body = {"access_token" : session.get("idToken")}
    let post_body = {name:firmware_name, version:version, firmware_data:data};
    axios.post(auth_url, auth_body).then(response => { 
      let authenticationToken = response.data.authenticationToken;
      let header = {"X-ZUMO-AUTH" : authenticationToken}
      axios.post(url,post_body ,{headers: header}).then(response => { 
          //console.log("Returning: ", JSON.stringify(response.data))
          callback(response.data)
        }).catch(function(error) {
          // error response flow
          callback(error);
        });
    })
}

export function GetDevices(callback){
  let URL = [
    "http://localhost:5263/api/Devices",
    "http://localhost:5000/api/Devices",
    "/api/Devices"
  ]
  let url = URL[2]
  console.log("url = ", url);
  let header = {"Authorization" : "Bearer " + session.get("accessToken")}
  axios.get(url, {headers: header}).then(response => { 
      callback(response.data)
  }).catch(error => {
    console.log(error);
  });
}

export function SetDesiredFirmware(device_ID, version, callback){
  let URL = [
    "http://localhost:5263/api/Devices",
    "http://localhost:5000/api/Devices",
    "/api/Devices"
  ]
  let url = URL[2]
  console.log("url = ", url);
  let header = {"Authorization" : "Bearer " + session.get("accessToken")}
  let body = {"device_ID" : device_ID, "details" : version, "action": "FW_Update"}
  axios.post(url,body, {headers: header}).then(response => { 
      callback(response.data)
  }).catch(error => {
    console.log(error);
  });
}