<template>
  <div class="header">
    Logged in as {{MyName}}
  </div>
</template>

<script>
import {session} from "../js/storage"
export default {
  name: 'Header',
  data() {
    return {
      MyName: undefined,
    };
  },
  created() {
     this.$emitter.on(
      'loginStatus', (status) => {
        if(status=="loggedin"){
          this.MyName=session.get("name");
        }
        
      }
    )
  }
};
</script>

<style>
  .header{
    background-color: #424242;
    padding:10px;
    border-radius: 7px;
    margin-left: 20px;
    display: inline-block;
  }
</style>