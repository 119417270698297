import { createSession } from 'the-storages'

// default, you can modify
const vueTheStoragesOptions = {
  localMirrorKey: '$localData',
  localStorageKey: '$local',
  sessionMirrorKey: '$sessionData',
  sessionStorageKey: '$session',

  options: {
    vueModule: null,
    strict: true,
    mirrorOperation: false,
    updateMirror: true
  }
}

export var isUserAuthenticated ;
export var session = {};

export function createsession()
{
  session = createSession(vueTheStoragesOptions);
  isUserAuthenticated = true;
}