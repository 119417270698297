<template>
  <div class="home">
    <h1>WM Solutions Firmware Status</h1>
      <div class="va-table-responsive">
        <table class="va-table va-table--hoverable">
          <thead>
          <tr>
            <th class="centeredText">DeviceUID</th>
            <th class="centeredText">Client</th>
            <th class="centeredText">Location</th>
            <th class="centeredText">Current Ver</th>
            <th class="centeredText">Desired Ver</th>
            <th class="centeredText">Available Ver</th>
            <th class="centeredText">Upgrade FW</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(device,i) in devices" :key="device.UID">
            <td class="centeredText">{{device.code}}</td>
            <td class="centeredText">{{device.client}}</td>
            <td class="centeredText">{{device.address}}</td>
            <td class="centeredText">{{device.actualVersion}}</td>
            <td class="centeredText">{{device.desiredVersion}}</td>
            <td class="centeredText"> <va-select v-model="selected_version[i]" :options="versions" /></td>
            <td class="centeredText"><va-button @click="FW_Upgrade_Click_Handler(i)"> FW Upgrade!</va-button></td>
          </tr>
          </tbody>
        </table>
  </div>
  </div>
</template>

<script>
import {GetDevices,GetFirmwareNames, GetVersions, SetDesiredFirmware} from "../js/cloudProcedures"

export default {
  name: 'Home',
   data() {
    return {
      device : {
        code: undefined,
        client: undefined,
        address : undefined,
        actualVersion: undefined,
        desiredVersion: undefined,
      },
      devices:[],
      versions:[],
      selected_version: []
    };
  },
  async mounted(){
    GetDevices( (devices) => {
      this.devices = devices;
      //console.log(devices);
    });
    GetFirmwareNames(result => {
        //console.log(result);
        this.versions = [];
        for (const firmware_name of result){
          GetVersions(firmware_name, versions=>{
                //console.log(versions);
                if (versions.versions.length>0){
                  for(const version of versions.versions){
                    this.selected_version = new Array(versions.versions.length + this.versions.length)
                    this.versions.push(firmware_name + " v" + version);
                    //console.log(this.versions);
                  }
                }
          })}
    });
  },
  methods:{
    FW_Upgrade_Click_Handler(i){
      //console.log(this.selected_version[i] + this.devices[i].code)
      SetDesiredFirmware(this.devices[i].code, this.selected_version[i], ()=>{
        this.$vaToast.init("Firmware Sent");
        GetDevices( (devices) => {
        this.devices = devices;
    });
      })

    }
  }
}
</script>

<style scoped>
.center {
  display: table;
  margin: 0 auto;
  border: 3px solid green;
  padding: 10px;
}

  .centeredText {
      text-align: center !important;
  }

  .va-button{
      font-size: small;
  }
</style>
