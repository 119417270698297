<template>
  <div class="sidenav">
    <va-sidebar textColor="dark" width="100%">
      <va-sidebar-item to="/devices">
        <va-sidebar-item-content>
          <va-icon name="network_wifi" />
          <va-sidebar-item-title> Devices </va-sidebar-item-title>
        </va-sidebar-item-content>
      </va-sidebar-item>
      <va-sidebar-item to="/upload">
        <va-sidebar-item-content>
          <va-icon name="file_upload" />
          <va-sidebar-item-title> Upload New Firmware </va-sidebar-item-title>
        </va-sidebar-item-content>
      </va-sidebar-item>
      <va-sidebar-item to="/about">
        <va-sidebar-item-content>
          <va-icon name="help_outline" />
          <va-sidebar-item-title> About </va-sidebar-item-title>
        </va-sidebar-item-content>
      </va-sidebar-item>
      <va-sidebar-item to="/logout">
        <va-sidebar-item-content>
           <va-icon name="logout" />
          <va-sidebar-item-title> Log Out </va-sidebar-item-title>
        </va-sidebar-item-content>
      </va-sidebar-item>
      
    </va-sidebar>
  </div>
</template>



<script>
export default {
    name: 'Sidebar'
}
</script>
  

<style >
body {
  font-family: "Lato", sans-serif;
}

.va-sidebar {
  background-color: #424242 !important;
  color: white !important;
}
.va-icon{
  color: #8dc63f !important;
}
.va-sidebar-item-title {
  font-size: 16px;
}
.sidebar{
  width: 250px;
}
.sidenav {
  height: 100%;
  /*width: 300px;*/
  /*width: 15%;*/
  width:100%;
  display: contents;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
  font-size: 18px;
  padding: 0px 10px;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
</style>