<template>
<div id="app" class="app">
   <div class="sidebar" >
    <Sidebar v-show="authenticated" />
  </div>
  <main class="main_container">
    <div class="header_container" >
      <Header/>
    </div>
    <div class="main_container_pages">
      <router-view/>
    </div>
  </main>
  </div>

</template>

<script>
import Sidebar from './components/Sidebar.vue'
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Sidebar,
    Header
  },
  data() {
    return {
      authenticated: false
    }
  },
  created() {
     this.$emitter.on(
      'loginStatus', (status) => {
        if(status=="loggedin"){
          this.authenticated = true;
        }
        
      }
    )
  },
}
</script>


<style>
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
h1{
    font-weight: 700 !important;
    font-size: 2em !important;
}
body {
  background-color: black !important;
}
th, td {
  text-align: left;
  padding: 8px;
  font-size: 0.8rem !important;
}

th{
  background-color: #777B7E !important;
  color: #8dc63f !important;
  font-size: 0.725rem !important;
}

tr:nth-child(even) td{
    background-color: #777B7E !important;
} 
tr:nth-child(odd) td{
    background-color: #999DA0 !important;
} 

.va-button {
  background-color: #424242 !important;
  padding: 10px;
}

.app {
  display: flex;
  width: 100%;
  height: auto;
}

.main_container {
  width: auto;
  max-width: 1500px;

  padding: 20px;
  margin: 0 auto;
  position: relative
}
.main_container_pages{
  margin-top: 20px;
}
.va-card {
    background-color: #424242 !important;
  color: whitesmoke !important;
}

.va-card__title {
  font-size: 1rem !important;
}

.main_container .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.main_container .form-control:focus {
  z-index: 2;
}

h1 {
  color: white;
}

.va-input__content__input{
    font-size: 0.8rem !important;
}

.va-button{
  font-size: 0.8rem !important;
}

.va-file-upload{
    font-size: 0.8rem !important;
}
</style>
